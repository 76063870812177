import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewsUiExtensionModule } from './extensions/cb1229b7ea2477caed312750ea0cdbf31c4145659559bb65fdcaf02eddf7910b/reviews-ui-extension.module';



@NgModule({
    imports: [CommonModule, ReviewsUiExtensionModule],
    providers: [],
})
export class SharedExtensionsModule {}
